import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { BarsIcon, LogoDarkIcon, LogoLightIcon } from "../assets/svg";
import { CLIENT_ACCESS_URL, IMMOVABLE_URL, REFERRALS_URL } from "../constants";
import { useOutsideClick } from "../hooks/useOutsideClick";
import Lang from "./Lang";
import MobileMenu from "./MobileMenu";

function Navbar() {
	const [openLang, setOpenLang] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const aboutRef = useOutsideClick(() => setOpenLang(null));
	const location = useLocation();
	const actualCrumb =
		location.pathname.split("/")[location.pathname.split("/").length - 1];
	const [t, i18n] = useTranslation("global");
	const lang = i18n.language;

	const handlePath = (path) => {
		return `${path}`;
	};

	if (
		(actualCrumb === "sell") |
		(actualCrumb === "rent") |
		(actualCrumb === "blog") |
		(actualCrumb === "work-with-us")
	) {
		return (
			<>
				<nav
					className={`fixed top-0 left-0 flex justify-between p-4 h-20 w-full z-30 bg-gray drop-shadow-lg duration-100 ease-in-out`}
				>
					<div>
						<Link to={handlePath("/")}>
							<LogoDarkIcon className="w-64 sm:w-72" />
						</Link>
					</div>
					<div className="flex items-center">
						<ul className="flex gap-4 items-center text-lg">
							<li
								className={`relative hidden xl:block cursor-pointer text-white hover:text-gray-300`}
								title={t("navbar.index")}
							>
								<Link to={handlePath("/")}>
									{t("navbar.index")}
								</Link>
							</li>
							<li
								ref={aboutRef}
								className={`relative hidden xl:block cursor-pointer text-white hover:text-gray-300`}
								onClick={() => setOpenLang(!openLang)}
								title={t("navbar.about_us.title")}
							>
								{t("navbar.about_us.title")}
								{openLang && (
									<div className="absolute top-[calc(100%_+_10px)] left-0 w-36 bg-gray-200 text-gray-500 text-sm rounded-lg overflow-hidden drop-shadow-lg">
										<ul>
											<li
												title={t(
													"navbar.about_us.who_we_are"
												)}
											>
												<Link
													className="flex items-center py-2.5 px-3 hover:bg-gray-300 cursor-pointer"
													to={handlePath("about-us")}
												>
													{t(
														"navbar.about_us.who_we_are"
													)}
												</Link>
											</li>
											<li className="h-0.5 w-full px-2">
												<div className="h-0.5 w-full bg-gray-300"></div>
											</li>
											<li
												title={t(
													"navbar.about_us.blog"
												)}
											>
												<Link
													to={handlePath("blog")}
													className="flex items-center py-2.5 px-3 hover:bg-gray-300 cursor-pointer"
												>
													{t("navbar.about_us.blog")}
												</Link>
											</li>
										</ul>
									</div>
								)}
							</li>
							<li
								className={`hidden xl:block cursor-pointer text-white hover:text-gray-300`}
								title={t("navbar.immovables")}
							>
								<Link to={IMMOVABLE_URL} target="_blank">
									{t("navbar.immovables")}
								</Link>
							</li>
							<li
								className={`hidden xl:block cursor-pointer text-white hover:text-gray-300`}
								title={t("navbar.earn")}
							>
								<Link to={REFERRALS_URL} target="_blank">
									{t("navbar.earn")}
								</Link>
							</li>
							<li
								className={`hidden xl:block cursor-pointer text-white hover:text-gray-300`}
								title={t("navbar.contact_us")}
							>
								<Link to={handlePath("contact-us")}>
									{t("navbar.contact_us")}
								</Link>
							</li>
							<li className="hidden xl:block">
								<Link
									className="py-1.5 px-2 bg-rose hover:bg-rose-light text-white rounded-md"
									to={CLIENT_ACCESS_URL}
									target="_blank"
									title={t("navbar.access")}
								>
									{t("navbar.access")}
								</Link>
							</li>
							<li>
								<Lang />
							</li>
							<li className="flex xl:hidden items-center">
								<button
									onClick={() => setOpenMenu(true)}
									title="Abrir menú"
								>
									<BarsIcon className="stroke-white h-6 w-6" />
								</button>
							</li>
						</ul>
					</div>
				</nav>

				<MobileMenu
					openMenu={openMenu}
					setOpenMenu={setOpenMenu}
					handlePath={handlePath}
				/>
			</>
		);
	} else {
		return (
			<>
				<nav
					className={`fixed top-0 left-0 flex justify-between p-4 w-full z-30 bg-white drop-shadow-lg duration-100 ease-in-out`}
				>
					<div>
						<Link to={handlePath("/")}>
							<LogoLightIcon className="w-64 sm:w-72" />
						</Link>
					</div>
					<div className="flex items-center">
						<ul className="flex gap-4 items-center text-lg">
							<li
								className={`hidden xl:block cursor-pointer text-gray-800 hover:text-gray-500`}
								title={t("navbar.index")}
							>
								<Link to={handlePath("/")}>
									{t("navbar.index")}
								</Link>
							</li>
							<li
								ref={aboutRef}
								className={`relative hidden xl:block cursor-pointer text-gray-800 hover:text-gray-500`}
								onClick={() => setOpenLang(!openLang)}
								title={t("navbar.about_us.title")}
							>
								{t("navbar.about_us.title")}
								{openLang && (
									<div className="absolute top-[calc(100%_+_10px)] left-0 w-36 bg-gray-200 text-gray-500 text-sm rounded-lg overflow-hidden drop-shadow-lg">
										<ul>
											<li
												title={t(
													"navbar.about_us.who_we_are"
												)}
											>
												<Link
													className="flex items-center py-2.5 px-3 hover:bg-gray-300 cursor-pointer"
													to={handlePath("about-us")}
												>
													{t(
														"navbar.about_us.who_we_are"
													)}
												</Link>
											</li>
											<li className="h-0.5 w-full px-2">
												<div className="h-0.5 w-full bg-gray-300"></div>
											</li>
											<li
												title={t(
													"navbar.about_us.blog"
												)}
											>
												<Link
													to={handlePath("blog")}
													className="flex items-center py-2.5 px-3 hover:bg-gray-300 cursor-pointer"
												>
													{t("navbar.about_us.blog")}
												</Link>
											</li>
										</ul>
									</div>
								)}
							</li>
							<li
								className={`hidden xl:block cursor-pointer text-gray-800 hover:text-gray-500`}
								title={t("navbar.immovables")}
							>
								<Link to={IMMOVABLE_URL} target="_blank">
									{t("navbar.immovables")}
								</Link>
							</li>
							<li
								className={`hidden xl:block cursor-pointer text-gray-800 hover:text-gray-500`}
								title={t("navbar.earn")}
							>
								<Link to={REFERRALS_URL} target="_blank">
									{t("navbar.earn")}
								</Link>
							</li>
							<li
								className={`hidden xl:block cursor-pointer text-gray-800 hover:text-gray-500`}
								title={t("navbar.contact_us")}
							>
								<Link to={handlePath("contact-us")}>
									{t("navbar.contact_us")}
								</Link>
							</li>
							<li className="hidden xl:block">
								<Link
									className="py-1.5 px-2 bg-rose hover:bg-rose-light text-white rounded-md"
									to={CLIENT_ACCESS_URL}
									target="_blank"
								>
									{t("navbar.access")}
								</Link>
							</li>
							<li>
								<Lang />
							</li>
							<li className="flex xl:hidden items-center">
								<button
									onClick={() => setOpenMenu(true)}
									title="Abrir menú"
								>
									<BarsIcon className="stroke-gray-600 h-6 w-6" />
								</button>
							</li>
						</ul>
					</div>
				</nav>
				<MobileMenu
					openMenu={openMenu}
					setOpenMenu={setOpenMenu}
					handlePath={handlePath}
				/>
			</>
		);
	}
}

export default Navbar;
