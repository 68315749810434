import { useFormik } from "formik";
import parse from "html-react-parser";
import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as yup from "yup";
import CarabanchelMap, { ExclamationCircleFilledIcon } from "../assets/svg";
import DragAndDrop from "../components/DragAndDrop";
import Footer from "../components/Footer";
import { WorkWithUsTemplate } from "../constants";
import proturboNotification from "../services/proturboNotification";
import wiseNotification from "../services/wiseNotification";
import delay from "../utils/delay";
import { Helmet } from "react-helmet-async";

export default function WorkWithUs() {
	const [t] = useTranslation("global");
	const [accept, setAccept] = useState(false);
	const [job, setJob] = useState(null);
	const [formTouched, setFormTouched] = useState(false);
	const formRef = useRef(null);
	const [uploadFiles, setUploadFiles] = useState();
	const jobsRef = useRef(null);

	const onSubmit = async (values) => {
		if (job !== null && accept === true) {
			setAccept(false);
			let content = { ...values };
			content.job = job;

			const fileBase64 = await toBase64(uploadFiles);

			const template = WorkWithUsTemplate(
				content.job,
				content.name,
				content.lastname,
				content.phone,
				content.email,
				content.comments ?? "No comments"
			);

			const body = {
				authuser: "no_reply@spagrupoinmobiliario.com",
				authpass: "MasivoSPA*2022",
				from: "no_reply@spagrupoinmobiliario.com",
				to: "miguelangel.fernandez@alquilerprotegido.es",
				subject: "Quiero trabajar",
				html_content: template,
				attachments: [
					{
						name: uploadFiles.name,
						type: uploadFiles.type,
						content: fileBase64,
					},
				],
			};

			console.log(body);
			resetForm();
			setUploadFiles()
			toast.success("Datos enviados");

			wiseNotification(content.phone);
			const response = await proturboNotification(body);
			setAccept(false);
			console.log(response);
		} else if (job === null) {
			scrollTopSmooth(window.scrollY, 2000);
			await delay(3000);
			setFormTouched(false);
		}
	};

	const scrollTopSmooth = (initY, duration = 2000) => {
		const timingFunc = (t) => t * (2 - t);
		let start = null;
		const step = (timestamp) => {
			start = start || timestamp;
			const progress = timestamp - start,
				// Growing from 0 to 1
				time = Math.min(1, (timestamp - start) / duration);

			window.scrollTo(0, initY - timingFunc(time) * initY);
			if (progress < duration) {
				window.requestAnimationFrame(step);
			}
		};

		window.requestAnimationFrame(step);
	};

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
		});

	const basicSchema = yup.object().shape({
		name: yup
			.string()
			.required(
				t("about_us.work_with_us.form.client_section.validations.name")
			),
		lastname: yup
			.string()
			.required(
				t(
					"about_us.work_with_us.form.client_section.validations.lastname"
				)
			),
		phone: yup
			.number()
			.positive()
			.min(10000)
			.max(10000000000000000000)
			.required(
				t("about_us.work_with_us.form.client_section.validations.phone")
			),
		email: yup
			.string()
			.email()
			.required(
				t("about_us.work_with_us.form.client_section.validations.email")
			),
		comments: yup.string(),
		accept: yup.boolean(),
	});

	const {
		values,
		touched,
		errors,
		handleBlur,
		handleChange,
		handleSubmit,
		resetForm,
	} = useFormik({
		initialValues: {
			name: "",
			lastname: "",
			phone: "",
			email: "",
			comments: "",
			accept: false,
		},
		validationSchema: basicSchema,
		onSubmit,
	});

	return (
		<>
			<Helmet>
				<title>Venta Garantizada | Trabaja con nosotros</title>
				<meta
					name="description"
					content="Únete a nuestro equipo en el sector inmobiliaria y da forma a tu futuro profesional. Descubre las oportunidades laborales que ofrecemos y sé parte de una empresa comprometida en ayudar a nuestros clientes."
				/>
				<link rel="canonical" href="/work-with-us" />
			</Helmet>
			<div className="relative flex flex-col gap-6 w-full text-gray-800 bg-gray-200 text-xl overflow-hidden">
				<div className="relative flex justify-center items-center py-8 pt-32 w-full bg-[#cbcbcc] overflow-hidden">
					<CarabanchelMap className="absolute -top-72 -left-28 min-w-[1000px] fill-[#ffffff] z-0" />
					<div className="flex flex-col items-center md:items-start md-flex-col gap-12 z-10">
						<div className="flex flex-col gap-6 px-8 w-fit text-gray-800">
							<h3 className="text-3xl font-semibold">
								{t("about_us.work_with_us.title")}
							</h3>
							<p className="text-xl font-medium">
								{t("about_us.work_with_us.location.title")}
							</p>
							<p
								className={`text-xl font-medium ${
									formTouched &&
									!job &&
									"text-rose underline animate-pulse"
								}`}
							>
								{t(
									"about_us.work_with_us.location.description"
								)}
							</p>
							<div
								ref={jobsRef}
								className="flex flex-col md:flex-row gap-4 w-full text-white"
							>
								<div
									className={`${
										job === "Asesor comercial" &&
										"bg-rose outline outline-offset-2"
									} flex flex-col gap-2 p-4 w-[360px] bg-gray border border-white rounded-2xl cursor-pointer ease-out duration-300`}
									onClick={() => setJob("Asesor comercial")}
								>
									<span className="text-2xl font-semibold">
										{t(
											"about_us.work_with_us.location.advisor.title"
										)}
									</span>
									<span>
										{parse(
											t(
												"about_us.work_with_us.location.advisor.description"
											)
										)}
									</span>
									<span className="font-semibold">
										{t(
											"about_us.work_with_us.location.advisor.features.title"
										)}
									</span>
									<ul className="list-disc list-inside">
										<li>
											{t(
												"about_us.work_with_us.location.advisor.features.list.0"
											)}
										</li>
										<li>
											{t(
												"about_us.work_with_us.location.advisor.features.list.1"
											)}
										</li>
										<li>
											{t(
												"about_us.work_with_us.location.advisor.features.list.2"
											)}
										</li>
									</ul>
								</div>
								<div
									className={`${
										job === "Colaborador" &&
										"bg-rose outline outline-offset-2"
									} flex flex-col gap-2 p-4 w-[360px] bg-gray border border-white rounded-2xl cursor-pointer ease-out duration-300`}
									onClick={() => setJob("Colaborador")}
								>
									<span className="text-2xl font-semibold">
										{t(
											"about_us.work_with_us.location.collaborator.title"
										)}
									</span>
									<span>
										{parse(
											t(
												"about_us.work_with_us.location.collaborator.description"
											)
										)}
									</span>
									<span className="font-semibold">
										{t(
											"about_us.work_with_us.location.collaborator.features.title"
										)}
									</span>
									<ul className="list-disc list-inside">
										<li>
											{t(
												"about_us.work_with_us.location.collaborator.features.list.0"
											)}
										</li>
										<li>
											{t(
												"about_us.work_with_us.location.collaborator.features.list.1"
											)}
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="flex flex-col gap-6 px-8 w-full text-gray-800">
							<h3 className="text-3xl font-semibold">
								{t("about_us.work_with_us.form.title")}
							</h3>
							<form
								ref={formRef}
								className="flex flex-col items-center gap-4 p-10 bg-gray rounded-2xl text-base"
								onSubmit={handleSubmit}
							>
								<div className="flex flex-col gap-1 w-full">
									<div className="relative w-full">
										<input
											name="name"
											type="text"
											placeholder={t(
												"about_us.work_with_us.form.client_section.name_placeholder"
											)}
											className={`block py-1.5 px-2 h-9 w-full ${
												errors.name && touched.name
													? "text-red-900 text-sm border border-red-300 focus:border-red-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-red-500"
													: "text-gray-900 text-sm border border-gray-300 focus:border-blue-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-blue-500"
											} outline-none`}
											value={values.name}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{errors.name && touched.name && (
											<ExclamationCircleFilledIcon className="absolute top-1/2 -translate-y-1/2 right-0 mr-2 h-5 w-5 bg-white fill-red-500" />
										)}
									</div>
									{errors.name && touched.name && (
										<p className="mt-1 text-red-500 text-sm font-medium">
											{errors.name}
										</p>
									)}
								</div>
								<div className="flex flex-col gap-1 w-full">
									<div className="relative w-full">
										<input
											name="lastname"
											type="text"
											placeholder={t(
												"about_us.work_with_us.form.client_section.lastname_placeholder"
											)}
											className={`block py-1.5 px-2 h-9 w-full ${
												errors.lastname &&
												touched.lastname
													? "text-red-900 text-sm border border-red-300 focus:border-red-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-red-500"
													: "text-gray-900 text-sm border border-gray-300 focus:border-blue-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-blue-500"
											} outline-none`}
											value={values.lastname}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{errors.lastname &&
											touched.lastname && (
												<ExclamationCircleFilledIcon className="absolute top-1/2 -translate-y-1/2 right-0 mr-2 h-5 w-5 bg-white fill-red-500" />
											)}
									</div>
									{errors.lastname && touched.lastname && (
										<p className="mt-1 text-red-500 text-sm font-medium">
											{errors.lastname}
										</p>
									)}
								</div>
								<div className="flex flex-col gap-1 w-full">
									<div className="relative w-full">
										<input
											name="phone"
											type="text"
											placeholder={t(
												"about_us.work_with_us.form.client_section.phone_placeholder"
											)}
											className={`block py-1.5 px-2 h-9 w-full ${
												errors.phone && touched.phone
													? "text-red-900 text-sm border border-red-300 focus:border-red-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-red-500"
													: "text-gray-900 text-sm border border-gray-300 focus:border-blue-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-blue-500"
											} outline-none`}
											value={values.phone}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{errors.phone && touched.phone && (
											<ExclamationCircleFilledIcon className="absolute top-1/2 -translate-y-1/2 right-0 mr-2 h-5 w-5 bg-white fill-red-500" />
										)}
									</div>
									{errors.phone && touched.phone && (
										<p className="mt-1 text-red-500 text-sm font-medium">
											{errors.phone}
										</p>
									)}
								</div>
								<div className="flex flex-col gap-1 w-full">
									<div className="relative w-full">
										<input
											name="email"
											type="text"
											placeholder={t(
												"about_us.work_with_us.form.client_section.email_placeholder"
											)}
											className={`block py-1.5 px-2 h-9 w-full ${
												errors.email && touched.email
													? "text-red-900 text-sm border border-red-300 focus:border-red-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-red-500"
													: "text-gray-900 text-sm border border-gray-300 focus:border-blue-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-blue-500"
											} outline-none`}
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
										/>
										{errors.email && touched.email && (
											<ExclamationCircleFilledIcon className="absolute top-1/2 -translate-y-1/2 right-0 mr-2 h-5 w-5 bg-white fill-red-500" />
										)}
									</div>
									{errors.email && touched.email && (
										<p className="mt-1 text-red-500 text-sm font-medium">
											{errors.email}
										</p>
									)}
								</div>
								<textarea
									name="comments"
									placeholder={t(
										"about_us.work_with_us.form.client_section.comments_placeholder"
									)}
									className={`block py-1.5 px-2 h-24 w-full ${
										errors.comments && touched.comments
											? "text-red-900 text-sm border border-red-300 focus:border-red-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-red-500"
											: "text-gray-900 text-sm border border-gray-300 focus:border-blue-500 rounded-md ring-1 ring-inset ring-transparent focus:ring-blue-500"
									} outline-none`}
									value={values.comments}
									onChange={handleChange}
									onBlur={handleBlur}
								/>
								<DragAndDrop
									uploadFiles={uploadFiles}
									setUploadFiles={setUploadFiles}
								/>
								<div className="relative flex items-center gap-2 w-full text-base sm:text-lg select-none text-gray-200">
									<input
										checked={accept}
										name="accept"
										type="checkbox"
										id="accept"
										onChange={(e) => {
											setAccept(!accept);
											handleChange(e);
										}}
										className={`block py-1.5 px-2 h-5 outline-none cursor-pointer`}
										value={values.accept}
										onBlur={handleBlur}
									/>
									{errors.accept && touched.accept && (
										<p className="mt-1 text-red-500 text-sm font-medium">
											{errors.accept}
										</p>
									)}
									<label
										htmlFor="accept"
										className="cursor-pointer"
									>
										{t(
											"about_us.work_with_us.form.accept_data_use.0"
										)}{" "}
										<Link
											to={
												"https://alquilerprotegido.es/es/politicas"
											}
											target="_blank"
											className="text-rose font-semibold underline"
										>
											{t(
												"about_us.work_with_us.form.accept_data_use.1"
											)}
										</Link>
									</label>
								</div>
								<button
									type="submit"
									className="p-2.5 w-full bg-rose text-base rounded-md text-white disabled:text-gray-500 disabled:bg-gray-300"
									onClick={() => setFormTouched(true)}
								>
									{t("about_us.work_with_us.form.button")}
								</button>
								<div className="flex flex-col gap-2 w-full">
									{formTouched && !accept && (
										<span className="text-red-500 font-semibold">
											{t(
												"about_us.work_with_us.form.client_section.validations.accept"
											)}
										</span>
									)}
									{formTouched && !job && (
										<span className="text-red-500 font-semibold">
											{t(
												"about_us.work_with_us.form.client_section.validations.job"
											)}
										</span>
									)}
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<Toaster />
		</>
	);
}
