export default async function proturboNotification(body) {
	/* payLoad = {
		method: "post",
		maxBodyLength: Infinity,
		url: "https://api.turbo-smtp.com/api/v2/mail/send",
		headers: {
			"Access-Control-Allow-Origin": "*",
			origin: "x-requested-with",
			"Access-Control-Allow-Headers":
				"POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
			"Content-Type": "application/json",
		},
		data: body,
		validateStatus: () => true,
	};

	const data = await axios(payLoad);
	console.log(data);*/

	var myHeaders = new Headers();
	myHeaders.append("GET", "POST", "OPTIONS");

	var requestOptions = {
        mode: "no-cors",
		redirect: "follow",
		method: "POST",
		headers: myHeaders,
		body: JSON.stringify(body),
	};

	fetch("https://api.turbo-smtp.com/api/v2/mail/send", requestOptions)
		.then((response) => response.text())
		.then((result) => console.log(result))
		.catch((error) => console.log("error", error));
}
