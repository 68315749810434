import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	ArrowTrendigUpIcon,
	CurrencyEuro,
	HomeIcon,
	KeyIcon,
} from "../assets/svg";
import Footer from "../components/Footer";
import { BUY_URL } from "../constants";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function Home() {
	const [t] = useTranslation("global");
	const { width } = useWindowDimensions();
	const [expand, setExpand] = useState(false);

	const handlePath = (path) => {
		return `/${path}`;
	};

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Helmet>
				<title>Venta Garantizada</title>
				<meta
					name="description"
					content="Equipo de profesionales especializado en la venta de inmuebles con rapidez y garantías. Honorarios fijos y sin sorpresas. Asesoramiento inmobiliario"
				/>
				<link rel="canonical" href="/" />
			</Helmet>
			<div className="fill relative mt-20 h-[90vh] w-full overflow-hidden">
				<h1 className="hidden">Venta garantizada</h1>
				<img src="/img/madrid.jpg" alt="Madrid by Florian Wehde" />
				<div className="absolute top-0 left-0 h-[90vh] w-screen bg-black opacity-50"></div>
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4">
					<div>
						<h2 className="text-white text-3xl font-semibold text-center">
							{t("index.hero.title")}
						</h2>
					</div>
					<div>
						<ul className="flex flex-col sm:flex-row items-center justify-between text-white text-xl">
							<div className="flex flex-row items-center justify-center w-full">
								<li>
									<button className="py-2 px-2.5 hover:bg-white hover:bg-opacity-30 rounded-md w-[120px] uppercase">
										<Link to={handlePath("sell")}>
											{t("index.hero.tabs.sell")}
										</Link>
									</button>
								</li>
								<li className="h-5 w-0.5 bg-white "></li>
								<li>
									<button className="py-2 px-2.5 hover:bg-white hover:bg-opacity-30 rounded-md w-[120px] uppercase">
										<Link to={handlePath("rent")}>
											{t("index.hero.tabs.rent")}
										</Link>
									</button>
								</li>
								<li className="h-5 w-0.5 bg-white "></li>
								<li>
									<button className="py-2 px-2.5 hover:bg-white hover:bg-opacity-30 rounded-md w-[120px] uppercase">
										<Link to={BUY_URL} target="_blank">
											{t("index.hero.tabs.buy")}
										</Link>
									</button>
								</li>
							</div>
						</ul>
					</div>
				</div>
			</div>
			<section className="flex items-center justify-center py-16">
				<div className="container flex flex-col gap-14 px-8">
					<div className="pl-4 text-gray-800 text-3xl font-semibold border-l-4 border-rose">
						<h3>{t("index.guarantee.title")}</h3>
					</div>
					<div className="flex flex-col xl:grid xl:grid-cols-3 xl:grid-rows-[1fr_0.35fr_1fr] gap-6">
						<div className="col-start-1 row-start-1 col-end-2 row-end-2 relative h-fit w-full xl:w-fit text-gray-600 text-xl z-0 ease-in duration-200 group">
							<div className="flex flex-row items-center gap-6">
								<div className="p-6 h-fit w-fit bg-gray-300 group-hover:bg-rose rounded-lg ease-out duration-300">
									<HomeIcon className="h-8 w-8 stroke-rose group-hover:stroke-white stroke-2 ease-out duration-300" />
								</div>
								<p className="font-normal">
									{parse(t("index.guarantee.cards.0.title"))}
								</p>
							</div>
						</div>
						<div className="col-start-2 row-start-1 col-end-3 row-end-2 relative h-fit w-full xl:w-fit text-gray-600 text-xl overflow-hidden z-0 ease-in duration-200 group">
							<div className="flex flex-row items-center gap-6">
								<div className="p-6 h-fit w-fit bg-gray-300 group-hover:bg-rose rounded-lg ease-out duration-300">
									<KeyIcon className="h-8 w-8 stroke-rose group-hover:stroke-white stroke-2 ease-out duration-300" />
								</div>
								<p className="font-normal">
									{parse(t("index.guarantee.cards.1.title"))}
								</p>
							</div>
						</div>
						<div className="col-start-1 row-start-2 col-end-3 row-end-4 relative w-full text-gray-600 text-xl overflow-hidden z-0 ease-in duration-200 group">
							<div className="flex flex-col gap-6">
								<div className="flex flex-row items-center gap-6">
									<div className="p-6 h-fit w-fit bg-gray-300 group-hover:bg-rose rounded-lg ease-out duration-300">
										<KeyIcon className="h-8 w-8 stroke-rose group-hover:stroke-white stroke-2 ease-out duration-300" />
									</div>
									<div>
										<h4>
											<strong>
												{parse(
													t(
														"index.guarantee.cards.2.title"
													)
												)}
											</strong>
										</h4>
									</div>
								</div>
								<ol className="list-disc list-inside leading-7">
									<li>
										{parse(
											t("index.guarantee.cards.2.list.0")
										)}
									</li>
									<li>
										{parse(
											t("index.guarantee.cards.2.list.1")
										)}
									</li>
									<li>
										{parse(
											t("index.guarantee.cards.2.list.2")
										)}
									</li>
								</ol>
							</div>
						</div>
						<div className="col-start-3 row-start-1 col-end-4 row-end-4 relative w-full xl:w-fit text-gray-600 text-xl overflow-hidden z-0 ease-in duration-200 group">
							<div className="flex flex-col gap-6 h-full">
								<div className="flex flex-row items-center gap-6">
									<div className="p-6 h-fit w-fit bg-gray-300 group-hover:bg-rose rounded-lg ease-out duration-300">
										<ArrowTrendigUpIcon className="h-8 w-8 stroke-rose group-hover:stroke-white stroke-2 ease-out duration-300" />
									</div>
									<div>
										<h4>
											<strong>
												{parse(
													t(
														"index.guarantee.cards.3.title"
													)
												)}
											</strong>
										</h4>
									</div>
								</div>
								<ol className="flex flex-col justify-between h-full list-disc list-inside">
									<li>
										{parse(
											t("index.guarantee.cards.3.list.0")
										)}
									</li>
									<li>
										{parse(
											t("index.guarantee.cards.3.list.1")
										)}
									</li>
									<li>
										{parse(
											t("index.guarantee.cards.3.list.2")
										)}
									</li>
									<li>
										{parse(
											t("index.guarantee.cards.3.list.3")
										)}
									</li>
								</ol>
								<div className="flex flex-row items-center gap-6">
									<div className="p-6 h-fit w-fit bg-gray-300 group-hover:bg-rose rounded-lg ease-out duration-300">
										<CurrencyEuro className="h-8 w-8 stroke-rose group-hover:stroke-white stroke-2 ease-out duration-300" />
									</div>
									<div>
										<h4>
											<strong>
												{parse(
													t(
														"index.guarantee.cards.3.footer"
													)
												)}
											</strong>
										</h4>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="flex flex-col items-center justify-center gap-14 py-16 px-8">
				<div className="container flex flex-col gap-14">
					<div className="pl-4 text-gray-800 text-3xl font-semibold border-l-4 border-rose">
						{<h3>{t("index.cities.title")}</h3>}
					</div>
				</div>
				<div className="grid grid-cols-[repeat(2,_49%)] auto-rows-[20vh] w-full md:grid-cols-[repeat(6,_15.5%)] md:auto-rows-[10vh] gap-2.5 md:w-3/4">
					<div
						className="flex fill relative md:col-[span_4] md:row-[span_4] rounded-xl overflow-hidden hover:scale-102 hover:z-20 hover:drop-shadow-xl ease-out duration-200"
						title="Madrid"
					>
						<img
							src="/img/madrid.jpg"
							alt="Madrid"
							layout="fill"
							objectfit="cover"
							objectposition="center"
						/>
						<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
						<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
							<h4>MADRID</h4>
						</div>
					</div>
					<div
						className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-20 hover:drop-shadow-xl ease-out duration-200"
						title="Toledo"
					>
						<img
							src="/img/cities/toledo.webp"
							alt="Toledo"
							layout="fill"
							objectfit="cover"
							objectposition="center"
						/>
						<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
						<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
							<h4>TOLEDO</h4>
						</div>
					</div>
					<div
						className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-20 hover:drop-shadow-xl ease-out duration-200"
						title="Segovia"
					>
						<img
							src="/img/cities/segovia.webp"
							alt="Segovia"
							layout="fill"
							objectfit="cover"
							objectposition="center"
						/>
						<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
						<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
							<h4>SEGOVIA</h4>
						</div>
					</div>
					{width > 640 ? (
						<>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-20 hover:drop-shadow-xl ease-out duration-200"
								title="Guadalajara"
							>
								<img
									src="/img/cities/guadalajara.webp"
									alt="Guadalajara"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4 className="text-base sm:text-2xl">
										GUADALAJARA
									</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Alicante"
							>
								<img
									src="/img/cities/alicante.webp"
									alt="Alicante"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>ALICANTE</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Sevilla"
							>
								<img
									src="/img/cities/sevilla.webp"
									alt="Sevilla"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>SEVILLA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Córdoba"
							>
								<img
									src="/img/cities/cordoba.webp"
									alt="Córdoba"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>CÓRDOBA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Valencia"
							>
								<img
									src="/img/cities/valencia.webp"
									alt="Valencia"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>VALENCIA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Granada"
							>
								<img
									src="/img/cities/granada.webp"
									alt="Granada"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>GRANADA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Bilbao"
							>
								<img
									src="/img/cities/bilbao.webp"
									alt="Bilbao"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>BILBAO</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Lugo"
							>
								<img
									src="/img/cities/lugo.webp"
									alt="Lugo"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>LUGO</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Vigo"
							>
								<img
									src="/img/cities/vigo.webp"
									alt="Vigo"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>VIGO</h4>
								</div>
							</div>
						</>
					) : expand ? (
						<>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-20 hover:drop-shadow-xl ease-out duration-200"
								title="Guadalajara"
							>
								<img
									src="/img/cities/guadalajara.webp"
									alt="Guadalajara"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4 className="text-base sm:text-2xl">
										GUADALAJARA
									</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-20 hover:drop-shadow-xl ease-out duration-200"
								title="Guadalajara"
							>
								<img
									src="/img/cities/guadalajara.webp"
									alt="Guadalajara"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4 className="text-base sm:text-2xl">
										GUADALAJARA
									</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Alicante"
							>
								<img
									src="/img/cities/alicante.webp"
									alt="Alicante"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>ALICANTE</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_2] md:row-[span_2] rounded-xl overflow-hidden hover:scale-103 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Sevilla"
							>
								<img
									src="/img/cities/sevilla.webp"
									alt="Sevilla"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>SEVILLA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Córdoba"
							>
								<img
									src="/img/cities/cordoba.webp"
									alt="Córdoba"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>CÓRDOBA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Valencia"
							>
								<img
									src="/img/cities/valencia.webp"
									alt="Valencia"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>VALENCIA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Granada"
							>
								<img
									src="/img/cities/granada.webp"
									alt="Granada"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>GRANADA</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Bilbao"
							>
								<img
									src="/img/cities/bilbao.webp"
									alt="Bilbao"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>BILBAO</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Lugo"
							>
								<img
									src="/img/cities/lugo.webp"
									alt="Lugo"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>LUGO</h4>
								</div>
							</div>
							<div
								className="flex fill relative md:col-[span_1] md:row-[span_1] rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200"
								title="Vigo"
							>
								<img
									src="/img/cities/vigo.webp"
									alt="Vigo"
									layout="fill"
									objectfit="cover"
									objectposition="center"
								/>
								<div className="absolute top-0 left-0 h-full w-full bg-black opacity-40"></div>
								<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-2xl text-white font-bold select-none">
									<h4>VIGO</h4>
								</div>
							</div>
							<div
								className="relative flex items-center justify-center md:col-[span_1] md:row-[span_1] bg-gray-200 rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200 cursor-pointer"
								onClick={() => setExpand(false)}
							>
								{t("index.cities.condense_button")}
							</div>
						</>
					) : (
						<div
							className="relative flex items-center justify-center md:col-[span_1] md:row-[span_1] bg-gray-200 rounded-xl overflow-hidden hover:scale-105 hover:z-30 hover:drop-shadow-xl ease-out duration-200 cursor-pointer"
							onClick={() => setExpand(true)}
						>
							{t("index.cities.expand_button")}
						</div>
					)}
				</div>
			</section>
			<Footer />
		</>
	);
}
