import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserIcon } from "../../assets/svg";
import parse from "html-react-parser";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

export default function AlquilerProtegido() {
	const [t] = useTranslation("global");

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Helmet>
				<title>{t("blog.alquiler-protegido-100-digital.title")}</title>
				<meta
					name="description"
					content="Descubre la revolución en el mundo inmobiliario en España a través de Alquiler Protegido: una experiencia 100% digital."
				/>
				<link rel="canonical" href="/blog/alquiler-protegido-100-digital" />
			</Helmet>
			<div className="fill relative h-[30vh] w-full overflow-hidden">
				<img src="/img/blog/keys.jpg" alt="Meeting room" />
				<div className="absolute top-0 left-0 h-[30vh] w-screen  bg-hero-pattern"></div>
				<div className="absolute bottom-6 flex flex-col items-start gap-4 w-full px-8">
					<div>
						<h2 className="text-white text-3xl font-semibold text-center line-clamp-3">
							{t("blog.alquiler-protegido-100-digital.title")}
						</h2>
					</div>
					<div>
						<div className="flex items-center gap-2 text-base text-white">
							<div className="p-1 bg-gray-300 rounded-full">
								<UserIcon className="flex items-center justify-center h-4 w-4 stroke-gray-500" />
							</div>
							<div>Soporte Alquiler</div>
							<div className="h-1 w-1 bg-gray-300 rounded-full"></div>
							<div>5 Agosto 2023</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-6 py-16 px-10 pb-0 md:px-44 md:pb-0">
				<p className="text-gray-700 font-normal">
					{parse(t("blog.alquiler-protegido-100-digital.content"))}
				</p>
			</div>
			<div className="flex flex-col items-center md:items-start gap-14 py-16 px-16 xl:px-44">
				<h3 className="text-2xl font-semibold">Posts relacionados</h3>
				<div className="flex flex-wrap justify-center md:justify-start gap-8">
					<Link
						to={"/blog/mejor-vender-o-alquilar-mi-vivienda-vacia"}
						className="flex flex-col gap-2 w-[335px] cursor-pointer"
					>
						<div className="rounded-2xl overflow-hidden">
							<img
								src="/img/blog/couple_sitting.jpg"
								alt="Couple sitting"
							/>
						</div>
						<div>
							<h3 className="text-2xl text-gray-600 font-semibold line-clamp-2">
								{t(
									"blog.mejor-vender-o-alquilar-mi-vivienda-vacia.title"
								)}
							</h3>
						</div>
						<div className="text-base text-gray-600 h-24">
							<p className="font-normal line-clamp-4">
								{parse(
									t(
										"blog.mejor-vender-o-alquilar-mi-vivienda-vacia.content"
									)
								)}
							</p>
						</div>
						<div className="flex items-center gap-2 text-sm text-gray-600">
							<UserIcon className="h-4 w-4 stroke-gray-600" />
							<div>Soporte Alquiler</div>
							<div>5 Agosto 2023</div>
						</div>
					</Link>
					<Link
						to={"/blog/rip-deal"}
						className="flex flex-col gap-2 w-[335px] cursor-pointer"
					>
						<div className="rounded-2xl overflow-hidden">
							<img
								src="/img/blog/hands.jpg"
								alt="Couple sitting"
							/>
						</div>
						<div>
							<h3 className="text-2xl text-gray-600 font-semibold line-clamp-2">
								{t("blog.rip-deal.title")}
							</h3>
						</div>
						<div className="text-base text-gray-600 h-24">
							<p className="font-normal line-clamp-4">
								{parse(t("blog.rip-deal.content"))}
							</p>
						</div>
						<div className="flex items-center gap-2 text-sm text-gray-600">
							<UserIcon className="h-4 w-4 stroke-gray-600" />
							<div>Soporte Alquiler</div>
							<div>16 Agosto 2023</div>
						</div>
					</Link>
				</div>
			</div>
			<Footer />
		</>
	);
}
