import React, { useState, useEffect } from "react";

function LoadingBar({ startLoading, ms }) {
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (startLoading) {
			// Start loading animation when startLoading prop is true
			setIsLoading(true);

			// Simulate loading by changing isLoading to false after 5 seconds
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, ms);

			// Clear the timer when the component unmounts to prevent memory leaks
			return () => clearTimeout(timer);
		}
	}, [startLoading, ms]);

	return (
		<div className="loading-bar-container text-white">
			{isLoading ? (
				<div className="loading-bar animate-pulse ">
					<div className="loading-progress"></div>
				</div>
			) : (
				<div className="h-[20px] w-full bg-rose"></div>
			)}
		</div>
	);
}

export default LoadingBar;
