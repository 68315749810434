import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
	FacebookIcon,
	InstagramIcon,
	LogoVerticalDarkIcon,
	PhoneIcon,
} from "../assets/svg";
import {
	ALICANTE_URL,
	CARABANCHEL_URL,
	FACEBOOK_URL,
	INSTAGRAM_URL,
	TETUAN_URL,
	VALLECAS_URL,
} from "../constants";

function Footer({ lang }) {
	const [t, i18n] = useTranslation("global");

	return (
		<footer className="flex flex-col gap-6 xl:flex-row xl:gap-0 justify-between p-10 md:py-16 md:px-24 md:items-center bg-gray text-white">
			<div>
				<LogoVerticalDarkIcon className="w-36 text-white" />
			</div>
			<div>
				<ul className="leading-8">
					<li>
						<Link to={"/"}>{t("footer.links.index")}</Link>
					</li>
					<li>
						<Link to={"/contact-us"}>
							{t("footer.links.contact_us")}
						</Link>
					</li>
					<li>
						<Link to={"/work-with-us"}>
							{t("footer.links.work")}
						</Link>
					</li>
					<li>
						<Link to={"https://alquilerprotegido.es/es/politicas"}>
							{t("footer.links.policies")}
						</Link>
					</li>
					<li>
						<Link
							to={
								"https://alquilerprotegido.es/es/catalogo/inmuebles/alquiler"
							}
						>
							{t("footer.links.book")}
						</Link>
					</li>
					<li>
						<Link to={"https://alquilerprotegido.es/es/FAQS"}>
							{t("footer.links.faq")}
						</Link>
					</li>
				</ul>
			</div>
			<div>
				<ul className="leading-8">
					<li className="flex flex-row items-center gap-2">
						<PhoneIcon className="h-5 w-5" />
						<Link to={"tel:664 48 48 48"}>664 48 48 48</Link>
					</li>
					<li className="flex flex-row items-center gap-2">
						<PhoneIcon className="h-5 w-5" />
						<Link to={"tel:664 40 08 80"}>664 40 08 80</Link>
					</li>
					<li>
						Madrid
						<ul className="list-inside list-disc">
							<li>
								<Link to={CARABANCHEL_URL} target="_blank">
									Carabanchel: Calle General Ricardos 125
								</Link>
							</li>
							<li>
								<Link to={VALLECAS_URL} target="_blank">
									Vallecas: Avenida Albufera 100
								</Link>
							</li>
							<li>
								<Link to={TETUAN_URL} target="_blank">
									Tetuán: Juan de Olías, 1
								</Link>
							</li>
						</ul>
					</li>
					<li>
						Alicante
						<ul className="list-inside list-disc">
							<li>
								<Link to={ALICANTE_URL} target="_blank">
									Alicante: Calle Pintor Lorenzo Casanova 19
								</Link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<div>
				<ul className="flex flex-col gap-8">
					<li>
						<div className="flex gap-4">
							<Link to={INSTAGRAM_URL} target="_blank">
								<InstagramIcon className="h-8 w-8 fill-white" />
							</Link>
							<Link to={FACEBOOK_URL} target="_blank">
								<FacebookIcon className="h-8 w-6 fill-white" />
							</Link>
						</div>
					</li>
					<li>
						{
							<Link
								to={"/work-with-us"}
								className="p-2.5 bg-rose rounded-md"
							>
								{t("footer.media.work")}
							</Link>
						}
					</li>
				</ul>
			</div>
		</footer>
	);
}

export default Footer;
