// External links
export const IMMOVABLE_URL =
	"https://alquilerprotegido.es/es/catalogo/inmuebles/alquiler";
export const REFERRALS_URL = "https://landing.alquilerprotegido.es/referidos";
export const CLIENT_ACCESS_URL =
	"https://misgestiones.alquilerprotegido.es/login";
export const BUY_URL =
	"https://www.alquilerprotegido.es/es/catalogo/inmuebles/venta";
export const RENT_URL = "https://alquilerprotegido.es/es/propietarios";

// Social media
export const INSTAGRAM_URL =
	"https://instagram.com/venta.garantizada?igshid=MzRlODBiNWFlZA==";
export const FACEBOOK_URL = "https://www.facebook.com/ventagarantizada.es/";
export const WHATSAPP_URL =
	"https://api.whatsapp.com/send?phone=34664400880&text=!Hola%2C%20necesito%20ayuda!";

// Offices address
export const CARABANCHEL_URL =
	"https://www.google.com/maps/place/Calle+del+Gral.+Ricardos,+125,+28019+Madrid,+Espa%C3%B1a/@40.3914088,-3.7319862,17z/data=!3m1!4b1!4m6!3m5!1s0xd422794b6710ee1:0x22c084d3d0315d05!8m2!3d40.3914089!4d-3.7271153!16s%2Fg%2F11cs8mg7wn?entry=ttu";
export const VALLECAS_URL =
	"https://www.google.com/maps/place/Av.+de+la+Albufera,+100,+28038+Madrid,+Espa%C3%B1a/@40.3939753,-3.6642453,17z/data=!3m1!4b1!4m6!3m5!1s0xd4225dfe262296b:0x1ebfd56e3ad10982!8m2!3d40.3939753!4d-3.6616704!16s%2Fg%2F11bw442qrh?entry=ttu";
export const TETUAN_URL =
	"https://www.google.com/maps/place/C.+de+Juan+de+Ol%C3%ADas,+1,+28020+Madrid,+Espa%C3%B1a/@40.4535219,-3.7051901,17z/data=!3m1!4b1!4m6!3m5!1s0xd4228fffe339023:0x430648e4ee237a32!8m2!3d40.4535219!4d-3.7026152!16s%2Fg%2F11cs7nv1km?entry=ttu";
export const ALICANTE_URL =
	"https://www.google.com/maps/place/C.+Pintor+Lorenzo+Casanova,+19,+03003+Alicante+(Alacant),+Alicante,+Espa%C3%B1a/@38.3418267,-0.4925252,17z/data=!3m1!4b1!4m6!3m5!1s0xd62364b7d62c547:0xd56af6c18c745aa5!8m2!3d38.3418267!4d-0.4899503!16s%2Fg%2F11c5k7cqx_?entry=ttu";

export const WorkWithUsTemplate = (
	job,
	name,
	lastname,
	phone,
	email,
	comments
) => `<!DOCTYPE html>
<html lang="en">
	<head>
		<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<link
			rel="stylesheet"
			href="https://recursos.spagrupoinmobiliario.com/fonts/univia/stylesheet.css"
		/>
		<title>Work with us</title>
		<style>
			/* Reset some default styles for email clients */
			body,
			p {
				margin: 0;
				padding: 0;
			}
			/* Set the background color and text color */
			body {
				background-color: #dee2e9;
				color: #333;
				font-family: "Univia Pro";
			}
			/* Add some spacing to the content */
			.container {
				width: 100%;
				max-width: 600px;
				margin: 0 auto;
				padding: 20px;
			}
			/* Style the header */
			.header {
				background-color: #007bff;
				color: #fff;
				text-align: center;
				padding: 20px;
			}
			/* Style the main content */
			.content {
				background-color: #fff;
				padding: 20px;
				border-radius: 5px;
				box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
			}
			p {
				color: #191847;
				font-size: 16px;
				font-weight: 400;
				line-height: 26px;
			}
			/* Style the CTA button */
			button {
				display: inline-block;
				background-color: #e4004b;
				color: #fff;
				padding: 10px 20px;
				text-decoration: none;
				border-radius: 5px;
			}
			a {
				color: #e4004b;
				text-decoration: none;
				font-weight: 500;
			}
		</style>
	</head>
	<body>
		<div align="center" style="padding: 32px 8px; background: #e2e2e2">
			<table
				role="presentation"
				border="0"
				cellpadding="0"
				cellspacing="0"
				style="
					width: 100%;
					margin: 0 auto;
					padding: 0 12px;
					max-width: 640px;
					overflow: hidden;
				"
			>
				<colgroup>
					<col width="64" style="width: 10%" />
					<col width="256" style="width: 40%" />
					<col width="135" style="width: 21.09375%" />
					<col width="175" style="width: 27.34375%" />
				</colgroup>
				<tbody>
					<tr>
						<td
							valign="top"
							colspan="2"
							style="
								vertical-align: top;
								height: 18px;
								padding: 16px;
							"
						>
							<img
								src="https://venta-garantizada.spagrupoinmobiliario.com/img/baseHorizontal.png"
								alt="Venta Garantizada logo"
								height="30"
								width="auto"
							/>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				border="0"
				cellpadding="0"
				cellspacing="0"
				style="
					width: 100%;
					margin: 0 auto;
					margin-top: 24px;
					max-width: 600px;
					border-radius: 8px;
					overflow: hidden;
					background-color: #ffffff;
					box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.04);
					box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
				"
			>
				<tbody>
					<tr>
						<td colspan="4" style="padding: 40px 40px 12px 40px">
							<p
								style="
									color: #191847;
									font-size: 36px;
									font-weight: bold;
									line-height: 44px;
								"
							>
								¡Hay un nuevo
								<span style="color:#e4004b;"> aplicante</span>!
							</p>
						</td>
					</tr>
					<tr>
						<td colspan="4" style="padding: 12px 40px 24px">
							<p>
								Se ha registrado una nueva aplicación a través
								del formulario de la página de
								<a
									href="https://venta-garantizada.spagrupoinmobiliario.com/"
									>Venta garantizada</a
								>
								por el cargo de
								<strong>${job}.</strong>
							</p>
						</td>
					</tr>
					<tr>
						<td
							colspan="4"
							style="border-top: 1px solid #e5e7eb; padding: 40px"
						>
							<div style="position: relative; overflow: hidden">
								<img
									src="https://recursos.spagrupoinmobiliario.com/images/logotipo.png"
									alt="Venta Garantizada logo"
									height="380"
									width="auto"
									style="
										position: absolute;
										bottom: 0;
										left: -210px;
										z-index: 10;
										opacity: 0.03;
									"
								/>
								<table
									border="0"
									cellpadding="0"
									cellspacing="0"
									style="
										margin: 0;
										padding: 0;
										width: 100%;
										background-color: #f5f5f5;
										border: 2px solid #e5e7eb;
										border-radius: 6px;
										box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.05);
										overflow: hidden;
									"
								>
									<colgroup>
										<col style="width: 30%" />
										<col style="width: 70%" />
									</colgroup>
									<tbody>
										<tr>
											<th
												colspan="2"
												style="
													padding: 20px 24px;
													font-size: 20px;
													background-color: #ffff;
													border-bottom: 2px solid #e5e7eb;
													text-align: left;
												"
											>
												Datos del aplicante
											</th>
										</tr>
										<tr>
											<th
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Cargo
											</th>
											<td
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Asesor Comercial
											</td>
										</tr>
										<tr>
											<th
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Nombre
											</th>
											<td
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												${name}
											</td>
										</tr>
										<tr>
											<th
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Apellidos
											</th>
											<td
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												${lastname}
											</td>
										</tr>
										<tr>
											<th
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Teléfono
											</th>
											<td
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												${phone}
											</td>
										</tr>
										<tr>
											<th
												style="
													padding: 16px 24px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Email
											</th>
											<td
												style="
													padding: 16px 24px;
													text-align: left;
												"
											>
												${email}
											</td>
										</tr>
										<tr>
											<th
												colspan="2"
												style="
													padding: 16px 24px 8px;
													text-align: left;
													vertical-align: top;
													border-bottom: 2px solid #e5e7eb;
												"
											>
												Comentarios
											</th>
										</tr>
										<tr>
											<td
												colspan="2"
												style="padding: 8px 24px 16px"
											>
												${comments}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
			<table
				border="0"
				cellpadding="0"
				cellspacing="0"
				style="
					width: 100%;
					margin: 0 auto;
					margin-top: 40px;
					max-width: 600px;
					overflow: hidden;
				"
			>
				<colgroup>
					<col style="width: 33%" />
					<col style="width: 33%" />
					<col style="width: 33%" />
				</colgroup>
				<tbody>
					<tr>
						<td style="padding-bottom: 12px">
							<img
								src="https://recursos.spagrupoinmobiliario.com/images/baseHorizontal.png"
								alt="Venta Garantizada logo"
								height="30"
								width="auto"
							/>
						</td>
					</tr>
					<tr>
						<td colspan="3" style="padding-bottom: 12px">
							<hr style="border: 1px solid #c5c5c5" />
						</td>
					</tr>
					<tr>
						<td colspan="3" style="padding-bottom: 12px">
							<p style="color: #6b7280; font-size: 12px">
								Si tiene algún inconveniente visualizando este
								email póngase en contacto con el equipo de TI.
							</p>
						</td>
					</tr>
					<tr>
						<td colspan="1"></td>
						<td
							colspan="2"
							style="
								font-size: 12px;
								height: 18px;
								line-height: 18px;
								width: 50%;
								color: #6b7280;
								text-align: right;
								padding: 16px;
							"
						>
							® Todos los derechos reservados a
							<a
								href="https://spagrupoinmobiliario.com"
								target="_blank"
								>SPA grupo inmobiliario.</a
							>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</body>
</html>
`;
