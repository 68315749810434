import { useState, useTransition } from "react";
import { useOutsideClick } from "../hooks/useOutsideClick";
import { English, Spanish } from "../assets/svg";
import { useTranslation } from "react-i18next";

function Lang() {
	const [isPending, startTransition] = useTransition();
	const [open, setOpen] = useState(false);
	const langRef = useOutsideClick(() => setOpen(null));
	const [t, i18n] = useTranslation("global");
	const lang = i18n.language;

	const handleChangeLang = (nextLang) => {
		i18n.changeLanguage(nextLang);
	};

	return (
		<div className="relative flex items-center drop-shadow-lg">
			<button
				ref={langRef}
				className="h-6 w-6 rounded-full overflow-hidden hover:outline outline-offset-1 outline-2 outline-white duration-50"
				onClick={() => setOpen(!open)}
			>
				{lang === "es" ? <Spanish /> : <English />}
				{open && (
					<div className="absolute top-[calc(100%_+_10px)] right-0 bg-gray-200 text-gray-500 text-base rounded-lg overflow-hidden">
						<ul>
							<li
								className="flex gap-2.5 items-center py-2.5 px-3 hover:bg-gray-300 cursor-pointer"
								title="Español"
								onClick={() => handleChangeLang("es")}
							>
								<div>
									<Spanish className="h-5 w-5 rounded-full overflow-hidden" />
								</div>
								<div>Español</div>
							</li>

							<li className="h-0.5 w-full px-2">
								<div className="h-0.5 w-full bg-gray-300"></div>
							</li>
							<li
								className="flex gap-2.5 items-center py-2.5 px-3 hover:bg-gray-300 cursor-pointer"
								title="English"
								onClick={() => handleChangeLang("en")}
							>
								<div>
									<English className="h-5 w-5 rounded-full overflow-hidden" />
								</div>
								<div>English</div>
							</li>
						</ul>
					</div>
				)}
			</button>
		</div>
	);
}

export default Lang;
