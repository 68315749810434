import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import PublicRoute from "./components/router/PublicRoutes";
import {
	ABOUT_US,
	BLOG,
	BLOG_ALQUILER_PROTEGIDO,
	BLOG_MEJOR_VENDER_O_ALQUILAR,
	BLOG_RIP_DEAL,
	CONTACT_US,
	EN_ABOUT_US,
	EN_BLOG,
	EN_BLOG_ALQUILER_PROTEGIDO,
	EN_BLOG_MEJOR_VENDER_O_ALQUILAR,
	EN_BLOG_RIP_DEAL,
	EN_INDEX,
	EN_RENT,
	EN_SELL,
	EN_WORK_WITH_US,
	INDEX,
	RENT,
	SELL,
	WORK_WITH_US,
} from "./config/routes/paths";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import ContactUs from "./pages/ContactUs";
import Home from "./pages/Home";
import Rent from "./pages/Rent";
import Sell from "./pages/Sell";
import AlquilerProtegido from "./pages/blog/AlquilerProtegido";
import MejorVenderOAlquilar from "./pages/blog/MejorVenderOAlquilar";
import RipDeal from "./pages/blog/RipDeal";
import WorkWithUs from "./pages/WorkWithUs";
import WhatsApp from "./components/WhatsApp";

function App() {
	return (
		<BrowserRouter>
			<Navbar />
			<WhatsApp />
			<Routes>
				<Route path={INDEX} element={<PublicRoute />}>
					<Route index path={INDEX} element={<Home />}></Route>
					<Route index path={SELL} element={<Sell />}></Route>
					<Route index path={RENT} element={<Rent />}></Route>
					<Route
						index
						path={CONTACT_US}
						element={<ContactUs />}
					></Route>
					<Route index path={ABOUT_US} element={<AboutUs />}></Route>
					<Route
						index
						path={WORK_WITH_US}
						element={<WorkWithUs />}
					></Route>
					<Route index path={BLOG} element={<Blog />}></Route>
					<Route
						index
						path={BLOG_MEJOR_VENDER_O_ALQUILAR}
						element={<MejorVenderOAlquilar />}
					></Route>
					<Route
						index
						path={BLOG_ALQUILER_PROTEGIDO}
						element={<AlquilerProtegido />}
					></Route>
					<Route
						index
						path={BLOG_RIP_DEAL}
						element={<RipDeal />}
					></Route>
				</Route>
				<Route path={EN_INDEX} element={<PublicRoute />}>
					<Route index path={EN_INDEX} element={<Home />}></Route>
					<Route index path={EN_SELL} element={<Sell />}></Route>
					<Route index path={EN_RENT} element={<Rent />}></Route>
					<Route
						index
						path={EN_ABOUT_US}
						element={<AboutUs />}
					></Route>
					<Route
						index
						path={EN_WORK_WITH_US}
						element={<WorkWithUs />}
					></Route>
					<Route index path={EN_BLOG} element={<Blog />}></Route>
					<Route
						index
						path={EN_BLOG_MEJOR_VENDER_O_ALQUILAR}
						element={<MejorVenderOAlquilar />}
					></Route>
					<Route
						index
						path={EN_BLOG_ALQUILER_PROTEGIDO}
						element={<AlquilerProtegido />}
					></Route>
					<Route
						index
						path={EN_BLOG_RIP_DEAL}
						element={<RipDeal />}
					></Route>
				</Route>
			</Routes>
		</BrowserRouter>
	);
}

export default App;
