import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { EnvelopIcon, PhoneIcon } from "../assets/svg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import proturboNotification from "../services/proturboNotification";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet-async";

export default function ContactUs() {
	const [t] = useTranslation("global");
	const [accept, setAccept] = useState(false);
	const formRef = useRef(null);

	const handleFormSubmit = async () => {
		setAccept(false);
		const formData = new FormData(formRef.current);
		let content = {};

		for (let [key, value] of formData.entries()) {
			content[key] = value;
		}

		const body = {
			authuser: "no_reply@spagrupoinmobiliario.com",
			authpass: "MasivoSPA*2022",
			from: "no_reply@spagrupoinmobiliario.com",
			to: "tecnologia@spagrupoinmobiliario.com",
			subject: "Contáctanos",
			html_content: JSON.stringify(content)
				.substring(2, JSON.stringify(content).length - 2)
				.replaceAll('":"', " ")
				.replaceAll('","', " - "),
		};

		console.log(body);
		toast.success("Datos enviados");

		const response = await proturboNotification(body);
		setAccept(false);
		console.log(response);
	};

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Helmet>
				<title>Venta Garantizada | Contacto</title>
				<meta
					name="description"
					content="Contacto con la empresa inmobiliaria Venta Garantizada. Teléfono y oficinas. Garantizamos la venta de inmuebles con rapidez y sin sorpresas"
				/>
				<link rel="canonical" href="/contact-us" />
			</Helmet>
			<div className="relative flex flex-col-reverse lg:flex-row justify-center items-center gap-6 lg:h-[100vh] w-full p-28 text-gray-800 bg-gray-200 text-xl overflow-hidden">
				<div className="flex flex-col gap-8 min-w-[375px] max-w-[450px]">
					<div className="flex flex-col gap-4">
						<span className="hidden lg:block text-3xl font-semibold">
							{t("contact.title")}
						</span>
						<span className="text-lg">
							{t("contact.description")}
						</span>
						<div className="flex items-center gap-3 text-base">
							<EnvelopIcon className="h-5 w-5" />
							contactanos@alquilerprotegido.es
						</div>
						<div className="flex items-center gap-3 text-base">
							<PhoneIcon className="h-5 w-5" />
							664 48 48 48
						</div>
						<div className="flex items-center gap-3 text-base">
							<PhoneIcon className="h-5 w-5" />
							664 40 08 50
						</div>
						<div className="flex flex-col gap-4">
							<span className="text-2xl font-semibold">
								{parse(t("contact.work.title"))}
							</span>
							<Link
								to={"/work-with-us"}
								className="p-3 bg-rose hover:bg-rose-light text-white text-xl w-fit rounded-md"
								title={t("contact.work.button")}
							>
								{t("contact.work.button")}
							</Link>
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-6 w-[375px]">
					<span className="block lg:hidden text-3xl font-semibold">
						{parse(t("contact.title"))}
					</span>
					<form
						ref={formRef}
						className="flex flex-col gap-4 p-6 bg-white rounded-lg text-base"
					>
						<div className="flex flex-col md:flex-row gap-6 w-full">
							<input
								name="name"
								type="text"
								placeholder={t("contact.form.name_placeholder")}
								required
								className="py-1.5 px-3 w-full border border-gray-300 rounded-md"
							/>
							<input
								name="phone"
								type="number"
								placeholder={t(
									"contact.form.phone_placeholder"
								)}
								required
								className="py-1.5 px-3 w-full border border-gray-300 rounded-md"
							/>
						</div>
						<input
							name="email"
							type="email"
							placeholder={t("contact.form.email_placeholder")}
							required
							className="py-1.5 px-3 border border-gray-300 rounded-md"
						/>
						<div className="flex flex-col md:flex-row gap-6 w-full">
							<input
								name="address"
								type="text"
								placeholder={t(
									"contact.form.address_placeholder"
								)}
								required
								className="py-1.5 px-3 w-full border border-gray-300 rounded-md"
							/>
							<input
								name="postal_code"
								type="number"
								placeholder={t(
									"contact.form.postal_code_placeholder"
								)}
								required
								className="py-1.5 px-3 w-full border border-gray-300 rounded-md"
							/>
						</div>
						<textarea
							name="comments"
							placeholder={t("contact.form.comments_placeholder")}
							className="py-1.5 px-3 border border-gray-300 rounded-md"
						/>
						<div className="flex gap-2 text-base">
							<input
								checked={accept}
								name="accept"
								type="checkbox"
								id="accept"
								required
								onChange={(e) => setAccept(!accept)}
							/>
							<label htmlFor="accept">
								{t("contact.form.accept_data_use")}
							</label>
						</div>
						<button
							type="button"
							className="p-2.5 w-full bg-rose text-base rounded-md text-white disabled:text-gray-500 disabled:bg-gray-200"
							onClick={() => {
								handleFormSubmit();
								formRef.current.reset();
							}}
							disabled={!accept}
						>
							{t("contact.form.button")}
						</button>
					</form>
				</div>
			</div>
			<Footer />
			<Toaster />
		</>
	);
}
