import React, { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
	ArrowUpTrayIcon,
	PDFIcon,
	TrashFilledIcon,
	WordIcon,
	XMarkFilledIcon,
	XMarkIcon,
} from "../assets/svg";
import LoadingBar from "./LoadingBar";

const acceptedFiles = [
	"application/msword",
	"msword",
	"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
	"application/pdf",
];

export default function DragAndDrop({ uploadFiles, setUploadFiles }) {
	const [t, i18n] = useTranslation("global");
	const fileRef = useRef();
	const [dragNDrop, setDragNDrop] = useState();
	const [loading, setLoading] = useState(false);

	const handleUploadFiles = async (e) => {
		console.log(e.target.files);
		if (e.target.files.length === 1) {
			let droppedFile = e.target.files[0];
			let ext = "application/" + droppedFile.type.split("/")[1];
			console.log(ext);

			if (acceptedFiles.includes(ext)) {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 py-1 rounded px-2 border bg-gray-100 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 py-1 rounded px-2 border bg-gray-100 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}
	};

	const handleDragNDropOver = (e) => {
		e.preventDefault();
		setDragNDrop(true);
	};

	const handleDrop = (e) => {
		e.preventDefault();

		if (e.dataTransfer.files.length > 0) {
			let droppedFile = e.dataTransfer.files[0];
			let ext = "application/" + droppedFile.type.split("/")[1];

			console.log(acceptedFiles, ext, acceptedFiles.includes(ext));

			if (acceptedFiles.includes(ext)) {
				toast.success("Archivo cargado exitosamente", {
					position: "center-top",
					style: {
						border: "1px solid #62D346",
					},
				});
				setUploadFiles(droppedFile);
				setLoading(true);
			} else {
				toast((t) => (
					<span className="flex items-center gap-1">
						<span>
							El archivo tipo <b>'{ext.toUpperCase()}'</b> cargado
							no coincide con el formato requerido <b>'CSV'.</b>
						</span>
						<button
							className="ml-2 py-1 rounded px-2 border bg-gray-100 text-gray-900"
							onClick={() => toast.dismiss(t.id)}
						>
							Cerrar
						</button>
					</span>
				));
			}
		} else {
			toast((t) => (
				<span className="flex items-center gap-1">
					<span>Inserción no válida.</span>
					<button
						className="ml-2 py-1 rounded px-2 border bg-gray-100 text-gray-900"
						onClick={() => toast.dismiss(t.id)}
					>
						Cerrar
					</button>
				</span>
			));
		}

		handleDragNDropOut();
	};

	const handleDragNDropOut = () => {
		setDragNDrop(false);
	};

	if (!uploadFiles) {
		return (
			<div
				dropping={dragNDrop === true ? "true" : "false"}
				onDragOver={handleDragNDropOver}
				onDragLeave={handleDragNDropOut}
				onDrop={handleDrop}
				className="droparea mt-2 flex justify-center w-full rounded-lg border-2 border-dashed border-white px-6 py-10 text-white"
			>
				<div className="flex flex-col items-center">
					<ArrowUpTrayIcon className="h-12 w-12 stroke-2 stroke-gray-200" />
					<div className="flex mt-4 text-base leading-6">
						<label
							forhtml="file-upload"
							className="relative cursor-pointer rounded-md font-semibold whitespace-nowrap"
						>
							<span
								className="text-rose-light hover:text-rose"
								title="Cargar archivo"
							>
								{t(
									"about_us.work_with_us.form.client_section.input.0"
								)}
							</span>
							<input
								ref={fileRef}
								id="file-upload"
								name="file-upload"
								type="file"
								className="sr-only"
								accept={acceptedFiles.join(", ")}
								onChange={(e) => handleUploadFiles(e)}
							/>
						</label>
						<p className="pl-1 whitespace-nowrap font-medium">
							{t(
								"about_us.work_with_us.form.client_section.input.1"
							)}
						</p>
					</div>
					<p className="text-xs leading-5">
						{t(
							"about_us.work_with_us.form.client_section.valid_formats"
						)}
					</p>
				</div>
			</div>
		);
	} else {
		return (
			<div className="droparea mt-2 flex justify-center w-full rounded-lg border-2 border-dashed border-white px-6 py-10 text-white">
				<div className="flex items-center justify-center gap-2 w-[300px]">
					{uploadFiles.name.split(".").pop() === "pdf" ? (
						<PDFIcon className="h-10 w-10" />
					) : (
						<WordIcon className="h-10 w-10" />
					)}
					<div className="w-full max-w-[250px] truncate">
						<span className="text-base">{uploadFiles.name}</span>
						<div className="flex items-center gap-2">
							{/* <div className="h-1 w-full bg-rose rounded-full"></div> */}
							<LoadingBar startLoading={loading} ms={3000} />
							<span className="flex items-center text-sm">
								100%
							</span>
						</div>
					</div>
					<XMarkFilledIcon
						className="h-10 w-10 fill-red-500 hover:fill-red-600 cursor-pointer"
						onClick={() => setUploadFiles()}
					/>
				</div>
			</div>
		);
	}
}
