import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import { MagnifyingGlassIcon, UserIcon } from "../assets/svg";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { Helmet } from "react-helmet-async";

export default function Blog() {
	const [t] = useTranslation("global");

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
		<Helmet>
			<title>Venta Garantizada | Blog</title>
			<meta
				name="description"
				content="Blog con publicación de noticias, información y artículos dedicados al mercado inmobiliario. Especializado en el alquiler y la venta de inmuebles"
			/>
			<link rel="canonical" href="/blog" />
		</Helmet>
			<div className="fill relative mt-20 h-[25vh] w-full overflow-hidden">
				<img src="/img/blog.jpg" alt="Meeting room" />
				<div className="absolute top-0 left-0 h-[90vh] w-screen bg-black opacity-50"></div>
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4">
					<div>
						<h2 className="text-white text-3xl font-semibold text-center">
							{t("blog.title")}
						</h2>
					</div>
				</div>
			</div>
			<section className="flex flex-col gap-14 py-16 px-16 xl:px-44 bg-gray-200">
				<div className="flex items-center justify-end gap-4">
					<input
						type="search"
						className="py-1.5 px-3 w-60 border border-gray-300 rounded-md"
						placeholder={t("blog.search_placeholder")}
					/>
					<MagnifyingGlassIcon className="h-6 w-6" />
				</div>
				<div
					className="flex flex-wrap justify-center gap-8"
					title={t(
						"blog.mejor-vender-o-alquilar-mi-vivienda-vacia.title"
					)}
				>
					<Link
						to={"/blog/mejor-vender-o-alquilar-mi-vivienda-vacia"}
						className="flex flex-col gap-2 w-[335px] cursor-pointer text-gray-600 hover:text-gray-500 group ease-out duration-300"
					>
						<div className="rounded-2xl overflow-hidden group-hover:opacity-80">
							<img
								src="/img/blog/couple_sitting.jpg"
								alt="Couple sitting"
							/>
						</div>
						<div>
							<h3 className="text-2xl font-semibold line-clamp-2">
								{t(
									"blog.mejor-vender-o-alquilar-mi-vivienda-vacia.title"
								)}
							</h3>
						</div>
						<div className="text-base h-24">
							<p className="font-normal line-clamp-4">
								{parse(
									t(
										"blog.mejor-vender-o-alquilar-mi-vivienda-vacia.content"
									)
								)}
							</p>
						</div>
						<div className="flex items-center gap-2 text-sm">
							<UserIcon className="h-4 w-4 stroke-gray-600" />
							<div>Soporte Alquiler</div>
							<div>5 Agosto 2023</div>
						</div>
					</Link>
					<Link
						to={"/blog/alquiler-protegido-100-digital"}
						className="flex flex-col gap-2 w-[335px] cursor-pointer text-gray-600 hover:text-gray-500 group ease-out duration-300"
						title={t("blog.alquiler-protegido-100-digital.title")}
					>
						<div className="rounded-2xl overflow-hidden group-hover:opacity-80">
							<img
								src="/img/blog/keys.jpg"
								alt="Couple sitting"
							/>
						</div>
						<div className="h-16">
							<h3 className="text-2xl font-semibold line-clamp-2">
								{t("blog.alquiler-protegido-100-digital.title")}
							</h3>
						</div>
						<div className="text-base">
							<p className="font-normal line-clamp-4">
								{parse(
									t(
										"blog.alquiler-protegido-100-digital.content"
									)
								)}
							</p>
						</div>
						<div className="flex items-center gap-2 text-sm">
							<UserIcon className="h-4 w-4 stroke-gray-600" />
							<div>Soporte Alquiler</div>
							<div>5 Agosto 2023</div>
						</div>
					</Link>
					<Link
						to={"/blog/rip-deal"}
						className="flex flex-col gap-2 w-[335px] cursor-pointer text-gray-600 hover:text-gray-500 group ease-out duration-300"
						title={t("blog.rip-deal.title")}
					>
						<div className="rounded-2xl overflow-hidden group-hover:opacity-80">
							<img
								src="/img/blog/hands.jpg"
								alt="Couple sitting"
							/>
						</div>
						<div>
							<h3 className="text-2xl font-semibold line-clamp-2">
								{t("blog.rip-deal.title")}
							</h3>
						</div>
						<div className="text-base h-24">
							<p className="font-normal line-clamp-4">
								{parse(t("blog.rip-deal.content"))}
							</p>
						</div>
						<div className="flex items-center gap-2 text-sm">
							<UserIcon className="h-4 w-4 stroke-gray-600" />
							<div>Soporte Alquiler</div>
							<div>16 Agosto 2023</div>
						</div>
					</Link>
				</div>
			</section>
			<Footer />
		</>
	);
}
