// Default routes
export const INDEX = "";
export const SELL = "/sell";
export const RENT = "/rent";
export const ABOUT_US = "/about-us";
export const WORK_WITH_US = "/work-with-us";
export const CONTACT_US = "/contact-us";
export const BLOG = "/blog";
export const BLOG_MEJOR_VENDER_O_ALQUILAR =
	"/blog/mejor-vender-o-alquilar-mi-vivienda-vacia";
export const BLOG_ALQUILER_PROTEGIDO = "/blog/alquiler-protegido-100-digital";
export const BLOG_RIP_DEAL = "/blog/rip-deal";

// English routes
export const EN_INDEX = "/en/";
export const EN_SELL = "/en/sell";
export const EN_RENT = "/en/rent";
export const EN_ABOUT_US = "/en/about-us";
export const EN_WORK_WITH_US = "/en/work-with-us";
export const EN_CONTACT_US = "/en/contact-us";
export const EN_BLOG = "/en/blog";
export const EN_BLOG_MEJOR_VENDER_O_ALQUILAR =
	"/en/blog/mejor-vender-o-alquilar-mi-vivienda-vacia";
export const EN_BLOG_ALQUILER_PROTEGIDO =
	"/en/blog/alquiler-protegido-100-digital";
export const EN_BLOG_RIP_DEAL = "/en/blog/rip-deal";
