import React from "react";
import { WhatsAppIcon } from "../assets/svg";
import { Link } from "react-router-dom";
import { WHATSAPP_URL } from "../constants";

export default function WhatsApp() {
	return (
		<div className="fixed bottom-0 right-0 pr-6 pb-6 z-20">
			<Link
				to={WHATSAPP_URL}
                target="_blank"
				className="flex items-center justify-center p-3 h-[70px] w-[70px] bg-whatsapp rounded-full hover:ring-1 hover:ring-black drop-shadow-lg cursor-pointer"
			>
				<WhatsAppIcon className="h-full w-full fill-white" />
			</Link>
		</div>
	);
}
