import React, { useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { RENT_URL } from "../constants";
import proturboNotification from "../services/proturboNotification";
import { Helmet } from "react-helmet-async";

export default function Rent() {
	const [t] = useTranslation("global");
	const [accept, setAccept] = useState(false);
	const formRef = useRef(null);

	const handleFormSubmit = async () => {
		setAccept(false);
		const formData = new FormData(formRef.current);
		let content = {};

		for (let [key, value] of formData.entries()) {
			content[key] = value;
		}

		const body = {
			authuser: "no_reply@spagrupoinmobiliario.com",
			authpass: "MasivoSPA*2022",
			from: "no_reply@spagrupoinmobiliario.com",
			to: "miguelangel.fernandez@alquilerprotegido.es,mayerlin.pinedo@alquilerprotegido.es",
			subject: "Propietario quiere alquilar su inmueble",
			html_content: JSON.stringify(content)
				.substring(2, JSON.stringify(content).length - 2)
				.replaceAll('":"', " ")
				.replaceAll('","', " - "),
		};

		console.log(body);
		toast.success("Datos enviados");

		const response = await proturboNotification(body);
		setAccept(false);
		console.log(response);
	};

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Helmet>
				<title>Venta Garantizada | Alquila</title>
				<meta
					name="description"
					content="Encuentra tu hogar ideal con nosotros. Explora nuestras opciones de alquiler y experimenta la comodidad y seguridad de confiar en una inmobiliaria de confianza."
				/>
				<link rel="canonical" href="/rent" />
			</Helmet>
			<div className="fill relative mt-20 h-[800px] w-full overflow-hidden">
				<img
					src="/img/sevilla.jpg"
					alt="Sevilla"
					layout="fill"
					objectfit="cover"
					objectposition="center"
				/>
				<div className="absolute top-0 left-0 h-[150vh] w-screen bg-black opacity-50"></div>
				<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4">
					<div className="flex flex-col items-center px-8 gap-5 w-full md:w-max">
						<span className="text-3xl text-white font-semibold">
							{t("rent.title")}
						</span>
						<span className="text-3xl text-white font-semibold">
							{t("rent.subtitle")}
						</span>
						<div className="flex flex-col md:flex-row gap-5">
							<Link
								to={RENT_URL}
								target="_blank"
								className="py-2 px-2.5 min-w-fit hover:bg-white hover:bg-opacity-30 rounded-md bg-white bg-opacity-30 font-semibold text-white"
							>
								{t("rent.button.rent")}
							</Link>
						</div>
						<form
							ref={formRef}
							className="flex flex-col gap-4 p-6 w-fit md:w-96 bg-white rounded-lg"
						>
							<span className="text-2xl font-bold">
								{t("rent.form.title")}
							</span>
							<input
								name="name"
								type="text"
								placeholder="Nombre"
								required
								className="py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<input
								name="email"
								type="text"
								placeholder="Email"
								required
								className="py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<input
								name="phone"
								type="number"
								placeholder="Teléfono"
								required
								className="py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<select
								name="type"
								placeholder="Tipo de cliente"
								required
								className="py-1.5 px-3 border border-gray-300 rounded-md"
							>
								<option>{t("rent.form.types.0")}</option>
								<option>{t("rent.form.types.1")}</option>
							</select>
							<textarea
								name="comments"
								placeholder="Comentarios"
								className="py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<div className="flex gap-2 text-sm">
								<input
									checked={accept}
									name="accept"
									type="checkbox"
									id="accept"
									required
									onChange={(e) => setAccept(!accept)}
								/>
								<label htmlFor="accept" className="select-none">
									{t("sell.form.accept_data_use")}
								</label>
							</div>
							<button
								type="button"
								className="p-2.5 w-full bg-rose text-base rounded-md text-white disabled:text-gray-500 disabled:bg-gray-200"
								onClick={() => {
									handleFormSubmit();
									formRef.current.reset();
								}}
								disabled={!accept}
							>
								{t("rent.form.button")}
							</button>
						</form>
					</div>
				</div>
			</div>
			<Footer />
			<Toaster />
		</>
	);
}
