import { useTranslation } from "react-i18next";
import CarabanchelMap from "../assets/svg";
import Footer from "../components/Footer";
import { useEffect, useRef, useState } from "react";
import proturboNotification from "../services/proturboNotification";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet-async";

function Sell() {
	const [t] = useTranslation("global");
	const [accept, setAccept] = useState(false);
	const formRef = useRef(null);

	const handleFormSubmit = async () => {
		setAccept(false);
		const formData = new FormData(formRef.current);
		let content = {};

		for (let [key, value] of formData.entries()) {
			content[key] = value;
		}

		const body = {
			authuser: "no_reply@spagrupoinmobiliario.com",
			authpass: "MasivoSPA*2022",
			from: "no_reply@spagrupoinmobiliario.com",
			to: "miguelangel.fernandez@alquilerprotegido.es,mayerlin.pinedo@alquilerprotegido.es",
			subject: "Propietario quiere vender inmueble",
			html_content: JSON.stringify(content)
				.substring(2, JSON.stringify(content).length - 2)
				.replaceAll('":"', " ")
				.replaceAll('","', " - "),
		};

		console.log(body);

		console.log(body);
		toast.success("Datos enviados");

		const response = await proturboNotification(body);
		setAccept(false);
		console.log(response);
	};

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Helmet>
				<title>Venta Garantizada | Vende</title>
				<meta
					name="description"
					content="Vende tu inmueble con profesionales¡Maximiza el valor de tu propiedad! Confía en nuestra experiencia en el mercado inmobiliario para vender tu inmueble de manera eficiente y rentable. Descubre cómo podemos ayudarte a obtener el mejor precio para tu propiedad"
				/>
				<link rel="canonical" href="/sell" />
			</Helmet>
			<div className="relative flex justify-center items-center py-28 h-auto w-full bg-[#cbcbcc] overflow-hidden">
				<CarabanchelMap className="absolute -top-72 -left-28 min-h-[1000px] min-w-[1000px] fill-[#FFFFFF] z-0" />
				<div className="flex flex-col lg:flex-row items-center md:items-start md-flex-col gap-12 z-10">
					<div className="flex flex-col gap-6 px-8 w-96">
						<span className="text-xl text-gray-800">
							{t("sell.form.legend")}
						</span>
						<form
							ref={formRef}
							className="flex flex-col items-center gap-4 p-6 bg-gray-100 rounded-lg shadow-lg"
						>
							<span className="text-2xl font-bold">
								{t("sell.form.client_section.title")}
							</span>
							<input
								name="name"
								type="text"
								placeholder={t(
									"sell.form.client_section.name_placeholder"
								)}
								required
								className="w-full py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<input
								name="lastname"
								type="text"
								placeholder={t(
									"sell.form.client_section.lastname_placeholder"
								)}
								required
								className="w-full py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<input
								name="phone"
								type="text"
								placeholder={t(
									"sell.form.client_section.phone_placeholder"
								)}
								required
								className="w-full py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<input
								name="email"
								type="text"
								placeholder={t(
									"sell.form.client_section.email_placeholder"
								)}
								required
								className="w-full py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<span className="text-2xl font-bold">
								{t("sell.form.property_section.title")}
							</span>
							<input
								name="address"
								type="text"
								placeholder={t(
									"sell.form.property_section.address_placeholder"
								)}
								required
								className="w-full py-1.5 px-3 border border-gray-300 rounded-md"
							/>
							<select
								name="type"
								placeholder={t(
									"sell.form.property_section.type_placeholder"
								)}
								required
								className="w-full py-1.5 px-3 border border-gray-300 rounded-md"
							>
								<option>
									{t("sell.form.property_section.types.0")}
								</option>
								<option>
									{t("sell.form.property_section.types.1")}
								</option>
								<option>
									{t("sell.form.property_section.types.2")}
								</option>
							</select>
							<div className="flex gap-2 text-sm">
								<input
									checked={accept}
									name="accept"
									type="checkbox"
									id="accept"
									required
									onChange={(e) => setAccept(!accept)}
								/>
								<label htmlFor="accept" className="select-none">
									{t("sell.form.accept_data_use")}
								</label>
							</div>
							<button
								type="button"
								className="p-2.5 w-full bg-rose text-base rounded-md text-white disabled:text-gray-500 disabled:bg-gray-200"
								onClick={() => {
									handleFormSubmit();
									formRef.current.reset();
								}}
								disabled={!accept}
							>
								{t("sell.form.submit_button")}
							</button>
						</form>
					</div>
					<div>
						<iframe
							src="
https://wcentrix.net/app/form_web.html?accountID=13352&wcboxID=95f5e42b65524bfbba74e1c5c8a6a21c"
							title="formulario wise"
							style={{ height: "450px", width: "100%" }}
						></iframe>
					</div>
					<div className="flex flex-col gap-6 px-8 w-fit text-gray-800 md:w-[500px]">
						<h3 className="text-3xl font-semibold">
							{t("sell.benefits.title")}
						</h3>
						<p className="text-xl">
							{t("sell.benefits.description")}
						</p>
						<ul className="flex flex-col gap-6 list-inside list-disc text-2xl">
							<li>{t("sell.benefits.list.0")}</li>
							<li>{t("sell.benefits.list.1")}</li>
							<li>{t("sell.benefits.list.2")}</li>
							<li>{t("sell.benefits.list.3")}</li>
							<li>{t("sell.benefits.list.4")}</li>
							<li>{t("sell.benefits.list.5")}</li>
						</ul>
						<div className="flex justify-end">
							<span className="text-xl text-right">
								- Venta garantizada
							</span>
						</div>
					</div>
				</div>
			</div>
			<Footer />
			<Toaster />
		</>
	);
}

export default Sell;
