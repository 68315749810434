import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { XMarkIcon } from "../assets/svg";
import { CLIENT_ACCESS_URL, IMMOVABLE_URL, REFERRALS_URL } from "../constants";

function MobileMenu({ openMenu, setOpenMenu, handlePath }) {
	const [t, i18n] = useTranslation("global");
	const lang = i18n.language;

	if (!openMenu) return;

	return (
		<div
			className="fixed top-0 left-0 h-full w-full bg-white z-50 overflow-hidden"
			onClick={() => setOpenMenu(false)}
		>
			<button title="Cerrar menú" onClick={() => setOpenMenu(false)}>
				<XMarkIcon className="absolute top-10 right-10 h-10 w-10 stroke-gray-700 cursor-pointer" />
			</button>
			<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xl w-max text-gray-700 font-semibold">
				<ul className="flex flex-col items-center gap-6">
					<li>
						<Link to={handlePath("/")}>{t("navbar.index")}</Link>
					</li>
					<li>
						<Link
							to={handlePath("about-us")}
						>
							{t("navbar.about_us.who_we_are")}
						</Link>
					</li>
					<li>
						<Link to={handlePath("blog")}>
							{t("navbar.about_us.blog")}
						</Link>
					</li>
					<li>
						<Link to={IMMOVABLE_URL} target="_blank">
							{t("navbar.immovables")}
						</Link>
					</li>
					<li>
						<Link to={REFERRALS_URL} target="_blank">
							{t("navbar.earn")}
						</Link>
					</li>
					<li>
						<Link to={handlePath("contact-us")}>
							{t("navbar.contact_us")}
						</Link>
					</li>
					<li>
						<button title={t("dict.access")}>
							<Link
								className="py-2 px-2.5 bg-rose hover:bg-rose-light text-white text-base rounded-md"
								to={CLIENT_ACCESS_URL}
								target="_blank"
							>
								{t("navbar.access")}
							</Link>
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default MobileMenu;
