export default async function wiseNotification(phone) {
	const endPointJWT =
		"https://api.wcx.cloud/core/v1/authenticate?user=spainmobiliario";

	const corsProxyUrl = "https://apifcbienco.spagrupoinmobiliario.com/";

	const myHeadersJWT = new Headers();
	myHeadersJWT.append("Content-Type", "application/json");
	myHeadersJWT.append("x-api-key", process.env.REACT_APP_WISE_X_API_KEY);
	myHeadersJWT.append("GET", "POST", "OPTIONS");

	const payLoadJWT = {
		method: "GET",
		headers: myHeadersJWT,
		mode: "no-cors",
		redirect: "follow",
	};

	try {
		const responseJWT = await fetch(corsProxyUrl + endPointJWT, payLoadJWT);
		if (!responseJWT.ok) {
			throw new Error(`Failed to fetch JWT: ${responseJWT.status}`);
		}
		const dataJWT = await responseJWT.json();
		const JWT = dataJWT.token;

		// Send message
		const message = {
			group_id: 23532,
			source_channel: "whatsapp",
			subject: "Prueba tecnología Venta Garantizada",
			status: "pending",
			tags: ["Creado por API", "Prueba tecnología Venta Garantizada"],
			activities: [
				{
					type: "user_reply",
					template: {
						template_id: 2443,
						parameters: [
							{
								key: "{{1}}",
								value: "Teléfono: 3208899956",
							},
							{
								key: "{{2}}",
								value: "WhatsApp: 3103727777",
							},
							{
								key: "{{3}}",
								value: "Correo: contactenos@bienco.com.co",
							},
							{
								key: "{{4}}",
								value: "Página: www.bienco.com.co",
							},
						],
					},
					contacts_to: [
						{
							phone: `57${phone}`,
						},
					],
				},
			],
		};

		const endPointMessage = "https://api.wcx.cloud/core/v1/cases";

		const myHeadersMessage = new Headers();
		myHeadersMessage.append("Content-Type", "application/json");
		myHeadersMessage.append(
			"x-api-key",
			process.env.REACT_APP_WISE_X_API_KEY
		);
		myHeadersMessage.append("Authorization", `Bearer ${JWT}`);

		const payLoadMessage = {
			method: "POST",
			headers: myHeadersMessage,
			mode: "no-cors",
			redirect: "follow",
			body: JSON.stringify(message),
		};

		const responseMessage = await fetch(endPointMessage, payLoadMessage);
		if (!responseMessage.ok) {
			throw new Error(
				`Failed to send message: ${responseMessage.status}`
			);
		}
		const dataMessage = await responseMessage.json();

		if (
			dataMessage.status === 400 &&
			dataMessage.error === "OPEN_CASES_EXIST"
		) {
			for (const e of dataMessage.opened_cases) {
				const endPointCloseCase = `https://api.wcx.cloud/core/v1/cases/${e}`;

				const myHeadersCloseCase = new Headers();
				myHeadersCloseCase.append("Content-Type", "application/json");
				myHeadersCloseCase.append(
					"x-api-key",
					process.env.REACT_APP_WISE_X_API_KEY
				);
				myHeadersCloseCase.append("Authorization", `Bearer ${JWT}`);

				const payLoadCloseCase = {
					method: "PUT",
					headers: myHeadersCloseCase,
					mode: "no-cors",
					redirect: "follow",
					body: JSON.stringify({
						status: "closed",
						tags: ["Cerrado por API"],
					}),
				};

				const responseCloseCase = await fetch(
					endPointCloseCase,
					payLoadCloseCase
				);
				if (!responseCloseCase.ok) {
					throw new Error(
						`Failed to close case: ${responseCloseCase.status}`
					);
				}
			}

			const endPointCloseCase_2 = `https://api.wcx.cloud/core/v1/cases/${dataMessage.case_id}`;

			const myHeadersCloseCase_2 = new Headers();
			myHeadersCloseCase_2.append("Content-Type", "application/json");
			myHeadersCloseCase_2.append(
				"x-api-key",
				process.env.REACT_APP_WISE_X_API_KEY
			);
			myHeadersCloseCase_2.append("Authorization", `Bearer ${JWT}`);

			const payLoadCloseCase_2 = {
				method: "PUT",
				headers: myHeadersCloseCase_2,
				mode: "no-cors",
				redirect: "follow",
				body: JSON.stringify({
					status: "closed",
					user_id: -1,
				}),
			};

			const responseCloseCase_2 = await fetch(
				endPointCloseCase_2,
				payLoadCloseCase_2
			);
			if (!responseCloseCase_2.ok) {
				throw new Error(
					`Failed to close case 2: ${responseCloseCase_2.status}`
				);
			}
		}
	} catch (error) {
		console.error("An error occurred:", error);
	}
}
