import parse from "html-react-parser";
import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import MapAlicante from "../components/maps/MapAlicante";
import MapCarabanchel from "../components/maps/MapCarabanchel";
import MapTetuan from "../components/maps/MapTetuan";
import MapVallecas from "../components/maps/MapVallecas";
import { Helmet } from "react-helmet-async";
import MapSpain from "../components/maps/MapSpain";
import { PinFilledIcon } from "../assets/svg";
import { Link } from "react-router-dom";

export default function AboutUs() {
	const [t] = useTranslation("global");
	const [position, setPosition] = useState({
		office: "Carabanchel",
		position: {
			lat: 40.3914088,
			lng: -3.7319862,
		},
	});

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: "smooth" });
	}, []);

	return (
		<>
			<Helmet>
				<title>Venta Garantizada | Nosotros</title>
				<meta
					name="description"
					content="Somos la inmobiliaria de confianza que te acompaña en cada paso de tu viaje. Ofrecemos una amplia gama de servicios, compra, venta, alquiler y gestión de propiedades."
				/>
				<link rel="canonical" href="/about-us" />
			</Helmet>
			<div className="relative flex flex-col gap-6 w-full text-gray-800 bg-gray-200 text-xl overflow-hidden">
				<section className="flex items-center justify-center py-24 pb-0 px-6 md:p-28 md:pb-0">
					<div className="container flex flex-col gap-14 md:px-8">
						<div className="pl-4 text-gray-800 text-3xl font-semibold border-l-4 border-rose">
							<h3>{t("about_us.about.title")}</h3>
						</div>
						<p className="font-medium">
							{parse(t("about_us.about.description"))}
						</p>
					</div>
				</section>
				<section className="flex items-center justify-center py-16 pt-10 px-6 md:p-28 md:pt-10">
					<div className="container flex flex-col items-center md:items-start gap-14 md:px-8">
						<div className="flex items-start pl-4 w-full text-gray-800 text-3xl font-semibold border-l-4 border-rose">
							<h3>{t("about_us.offices.title")}</h3>
						</div>
						<div className="grid grid-cols-2 grid-rows-2 sm:grid-cols-4 sm:grid-rows-1 w-full rounded-xl text-base sm:text-xl border-gray-300 overflow-hidden shadow-md">
							<div
								className={`flex flex-row items-center justify-center gap-2 col-start-1 col-end-2 sm:row-start-1 sm:row-end-1 ${
									position.office === "Carabanchel" &&
									"bg-rose hover:bg-rose text-white border-rose"
								} py-4 px-6 w-full text-center bg-gray-100 hover:bg-gray-300 border-b-2 border-r-2 sm:border-0 cursor-pointer ease-out duration-300`}
								onClick={() =>
									setPosition({
										office: "Carabanchel",
										position: {
											lat: 40.3914088,
											lng: -3.7319862,
										},
									})
								}
							>
								{position.office === "Carabanchel" && (
									<PinFilledIcon className="fill-white h-6 w-6" />
								)}
								Carabanchel
							</div>
							<div
								className={`flex flex-row items-center justify-center gap-2 col-start-2 col-end-3 sm:row-start-1 sm:row-end-1 ${
									position.office === "Tetuán" &&
									"bg-rose hover:bg-rose text-white border-rose"
								} py-4 px-6 w-full text-center bg-gray-100 hover:bg-gray-300 border-b-2 border-l-2 sm:border-0 cursor-pointer ease-out duration-300`}
								onClick={() =>
									setPosition({
										office: "Tetuán",
										position: {
											lat: 40.4535219,
											lng: -3.7051901,
										},
									})
								}
							>
								{position.office === "Tetuán" && (
									<PinFilledIcon className="fill-white h-6 w-6" />
								)}
								Tetuán
							</div>
							<div
								className={`flex flex-row items-center justify-center gap-2 col-start-1 col-end-2 row-start-2 row-end-2 sm:col-start-3 sm:col-end-4 sm:row-start-1 sm:row-end-1 ${
									position.office === "Vallecas" &&
									"bg-rose hover:bg-rose text-white border-rose"
								} py-4 px-6 w-full text-center bg-gray-100 hover:bg-gray-300 border-t-2 border-r-2 sm:border-0 cursor-pointer ease-out duration-300`}
								onClick={() =>
									setPosition({
										office: "Vallecas",
										position: {
											lat: 40.3939753,
											lng: -3.6642453,
										},
									})
								}
							>
								{position.office === "Vallecas" && (
									<PinFilledIcon className="fill-white h-6 w-6" />
								)}
								Vallecas
							</div>
							<div
								className={`flex flex-row items-center justify-center gap-2 col-start-2 col-end-3 row-start-2 row-end-2 sm:col-start-4 sm:col-end-5 sm:row-start-1 sm:row-end-1 ${
									position.office === "Alicante" &&
									"bg-rose hover:bg-rose text-white border-rose"
								} py-4 px-6 w-full text-center bg-gray-100 hover:bg-gray-300 border-t-2 border-l-2 sm:border-0 cursor-pointer ease-out duration-300`}
								onClick={() =>
									setPosition({
										office: "Alicante",
										position: {
											lat: 38.3418267,
											lng: -0.4925252,
										},
									})
								}
							>
								{position.office === "Alicante" && (
									<PinFilledIcon className="fill-white h-6 w-6" />
								)}
								Alicante
							</div>
						</div>
						<MapSpain position={position.position} />
					</div>
				</section>
				<section className="px-6 sm:p-11 pt-0">
					<div className="relative flex flex-col gap-6 w-inherit md:w-fit mx-8 md:mx-28 p-11 bg-rose text-white rounded-2xl text-2xl overflow-hidden z-0">
						<img
							src="/img/pattern_2.jpg"
							alt="By bady abbas"
							layout="fill"
							objectfit="cover"
							objectposition="center"
							className="absolute top-0 left-0 -z-10 mix-blend-multiply opacity-40 blur-xs"
						/>
						<h3 className="text-3xl md:text-4xl">
							<strong>{t("index.add.title")}</strong>
						</h3>
						<span className="font-semibold">
							{t("index.add.description")}
						</span>
						<div className="flex flex-col gap-6 md:flex-row md:gap-0 justify-between items-center text-xl">
							<span>{t("index.add.information")}</span>
							<Link
								to={"/work-with-us"}
								className="p-4 bg-white hover:bg-gray-200 text-rose font-semibold rounded-lg"
							>
								{t("index.add.button")}
							</Link>
						</div>
					</div>
				</section>
			</div>
			<Footer />
			<Toaster />
		</>
	);
}
