import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import React from "react";

export default function MapSpain({ position }) {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
	});

	if (!isLoaded) return;

	return (
		<div className="h-72 w-full rounded-xl overflow-hidden drop-shadow-lg">
			<GoogleMap
				zoom={15}
				center={position}
				mapContainerClassName="map-container"
			>
				<MarkerF position={position} />
			</GoogleMap>
		</div>
	);
}
